import React from 'react'
import { graphql, Link } from "gatsby";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Layout from "../../components/Layout";
import Slider from "react-slick";
import postFeedback from "../../components/PostFeedback";
import SEO from "../../components/SEO";

class esign extends React.Component {
    constructor(props) {
        super(props);
        this.increaseImageWidthRow1 = this.increaseImageWidthRow1.bind(this);
        this.increaseImageWidthRow2 = this.increaseImageWidthRow2.bind(this);
        this.increaseImageWidthRow3 = this.increaseImageWidthRow3.bind(this);
        this.hitButtonLiveDemoeSign = this.hitButtonLiveDemoeSign.bind(this);
        this.hitButtonPricingeSign = this.hitButtonPricingeSign.bind(this);
    }

    // componentDidMount() {
    //     const { location } = this.props;

    //     var host_website = location.host ? location.host : "";
    //     var new_online_doc_url;

    //     if (host_website === "localhost:8000" || host_website === "127.0.0.1:8000") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "protoweb.appvity.com:8082") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "vmweb:8082") {
    //         new_online_doc_url = "http://vmweb:8083/";
    //     } else if (host_website === "10.8.30.46:8082") {
    //         new_online_doc_url = "http://10.8.30.46:8083/";
    //     } else if (host_website === "10.9.9.104:8082") {
    //         new_online_doc_url = "http://10.9.9.104:8083/";
    //     } else {
    //         new_online_doc_url = "https://www.appvity.com:8083/";
    //     }

    //     document.querySelector(".app-section-esign-try-product-free .app-esign-try-product-free-detail .app-esign-try-product-free-faqs-onlinedoc a.app-esign-try-product-free-faqs-onlinedoc-a-tag").setAttribute("href", new_online_doc_url + "odoc/07-00-00.html");
    // }

	increaseImageWidthRow1() {
        document.querySelector('.app-section-esign .app-esign-row-1 .app-esign-row-1-col-left').classList.toggle('app-esign-row-1-col-left-active');
        document.querySelector('.app-section-esign .app-esign-row-1 .app-esign-row-1-col-right').classList.toggle('app-esign-row-1-col-right-active');

        document.querySelector('.app-section-esign .app-esign-row-2 .app-esign-row-2-col-left').classList.remove('app-esign-row-2-col-left-active');
        document.querySelector('.app-section-esign .app-esign-row-2 .app-esign-row-2-col-right').classList.remove('app-esign-row-2-col-right-active');
        document.querySelector('.app-section-esign .app-esign-row-3 .app-esign-row-3-col-left').classList.remove('app-esign-row-3-col-left-active');
        document.querySelector('.app-section-esign .app-esign-row-3 .app-esign-row-3-col-right').classList.remove('app-esign-row-3-col-right-active');
    }

    increaseImageWidthRow2() {
        document.querySelector('.app-section-esign .app-esign-row-2 .app-esign-row-2-col-left').classList.toggle('app-esign-row-2-col-left-active');
        document.querySelector('.app-section-esign .app-esign-row-2 .app-esign-row-2-col-right').classList.toggle('app-esign-row-2-col-right-active');

        document.querySelector('.app-section-esign .app-esign-row-1 .app-esign-row-1-col-left').classList.remove('app-esign-row-1-col-left-active');
        document.querySelector('.app-section-esign .app-esign-row-1 .app-esign-row-1-col-right').classList.remove('app-esign-row-1-col-right-active');
        document.querySelector('.app-section-esign .app-esign-row-3 .app-esign-row-3-col-left').classList.remove('app-esign-row-3-col-left-active');
        document.querySelector('.app-section-esign .app-esign-row-3 .app-esign-row-3-col-right').classList.remove('app-esign-row-3-col-right-active');
    }

    increaseImageWidthRow3() {
        document.querySelector('.app-section-esign .app-esign-row-3 .app-esign-row-3-col-left').classList.toggle('app-esign-row-3-col-left-active');
        document.querySelector('.app-section-esign .app-esign-row-3 .app-esign-row-3-col-right').classList.toggle('app-esign-row-3-col-right-active');

        document.querySelector('.app-section-esign .app-esign-row-1 .app-esign-row-1-col-left').classList.remove('app-esign-row-1-col-left-active');
        document.querySelector('.app-section-esign .app-esign-row-1 .app-esign-row-1-col-right').classList.remove('app-esign-row-1-col-right-active');
        document.querySelector('.app-section-esign .app-esign-row-2 .app-esign-row-2-col-left').classList.remove('app-esign-row-2-col-left-active');
        document.querySelector('.app-section-esign .app-esign-row-2 .app-esign-row-2-col-right').classList.remove('app-esign-row-2-col-right-active');
    }

    hitButtonLiveDemoeSign() {
        var data = {};
        data["type"] = "click";
        data["button"] = "liveDemoeSign";
        
        var checkItemLocalStorage = localStorage.getItem("dataLiveDemoeSign");

        if (!checkItemLocalStorage) {
            postFeedback(`${process.env.feedbackAPI}`, data, 
            function(response) {
                console.log(response);
                localStorage.setItem("dataLiveDemoeSign", JSON.stringify(data));
            },
            function(error) {
                console.log("Something went wrong", error);
            });
        }
    };

    hitButtonPricingeSign() {
        var data = {};
        data["type"] = "click";
        data["button"] = "pricingeSign";
        
        var checkItemLocalStorage = localStorage.getItem("dataPricingeSign");

        if (!checkItemLocalStorage) {
            postFeedback(`${process.env.feedbackAPI}`, data, 
            function(response) {
                console.log(response);
                localStorage.setItem("dataPricingeSign", JSON.stringify(data));
            },
            function(error) {
                console.log("Something went wrong", error);
            });
        }
    };

    render() {
        const { data } = this.props;
        const introduction = data.allMarkdownRemark.nodes;
        const row_1 = data.row_1.nodes;
        const row_2 = data.row_2.nodes;
        const row_3 = data.row_3.nodes;
        const practical_features_introduction = data.practical_features_introduction.nodes;
        const practical_features_customer_portal = data.practical_features_customer_portal.nodes;

        var customSettingsSupportCustomerPortal = {
            dots: false,
            speed: 500,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1367,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 1279,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <Layout>
                <SEO title="eSign" description="Ticket or Issue management system for your enterprise that run on Microsoft Teams. Helpdesk system to improve customer's satisfaction and reliable tool for issue tracking." meta={[{ name: `keywords`, content: ["collaboration tools on Microsoft Teams", "helpdesk system on Microsoft Teams", "ticket management", "issue management"] }]} pathname="/products/esign" />
                <div className="main-content-padding">
                    <div className="app-general-seciton app-esign-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-esign-introduction-detail">
                                <Row className="app-esign-introduction-row-custom">
                                    <Col className="app-esign-introduction-col-custom" xs={12} md={12} lg={5}>
                                        <div className="app-esign-introduction-left">
                                            {introduction.map((res) => (
                                                <div dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={7} className='app-esign-introduction-col-right'></Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-esign" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-title">
                                        <h2>Revolutionize your entire agreement workflow with eSign</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-esign-detail">
                                <Row className="app-esign-row-custom app-esign-row-1">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-esign-col-custom app-esign-row-1-col-left" xs={12} md={12} lg={12} xl={5}>
                                        {row_1.map((res) => (
                                            <div className="app-esign-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col className="app-esign-col-custom app-esign-row-1-col-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-esign-right-side">
                                            <img 
                                                className="app-esign-row-1-img" 
                                                src="/app-esign-01.svg" 
                                                alt="app esign row 1" 
                                                onClick={this.increaseImageWidthRow1} 
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-esign-row-custom app-esign-row-2">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-esign-col-custom app-esign-row-2-col-left" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-esign-left-side">
                                            <img 
                                                className="app-esign-row-2-img" 
                                                src="/app-esign-02.svg" 
                                                alt="app esign row 2" 
                                                onClick={this.increaseImageWidthRow2} 
                                            />
                                        </div>
                                    </Col>
                                    <Col className="app-esign-col-custom app-esign-row-2-col-right" xs={12} md={12} lg={12} xl={5}>
                                        {row_2.map((res) => (
                                            <div className="app-esign-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-esign-row-custom app-esign-row-3">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-esign-col-custom app-esign-row-3-col-left" xs={12} md={12} lg={12} xl={5}>
                                        {row_3.map((res) => (
                                            <div className="app-esign-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col className="app-esign-col-custom app-esign-row-3-col-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-esign-right-side">
                                            <img 
                                                className="app-esign-row-3-img" 
                                                src="/app-esign-03.svg" 
                                                alt="app esign row 3" 
                                                onClick={this.increaseImageWidthRow3} 
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={2} xl={1}></Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-esign-practical-features" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    {practical_features_introduction.map((res) => (
                                        <div className="app-title" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-esign-practical-features-detail">
                                <div className="app-esign-practical-features-tabs">
                                    <Slider {...customSettingsSupportCustomerPortal}>
                                        {practical_features_customer_portal.map((res) => (
                                            <div className="app-practical-features-slider" key={res.id}>
                                                <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                <div className="content">
                                                    <h5>{res.frontmatter.title}</h5>
                                                    <h6>{res.frontmatter.description}</h6>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default esign

export const query = graphql`
    query eSignPage {
        allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "esign_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
		row_1: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "esign_row_1" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_2: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "esign_row_2" } } }
        ) {
            nodes {
                html
                id
            }
        }
        row_3: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "esign_row_3" } } }
        ) {
            nodes {
                html
                id
            }
        }
        practical_features_introduction: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "esign_practical_features_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
        practical_features_customer_portal: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "esign_practical_features_customer_portal" } } }
            sort: { fields: frontmatter___keyNumber, order: ASC }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
    }
`;
